<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="statdata" ref="dt" v-model:selection="selectedRow" :metaKeySelection="false"
                    v-model:filters="filters1" filterDisplay="menu" dataKey="id" :tableStyle="styleHeight"
                    :scrollHeight="sHeight" :scrollable="true" :virtualScrollerOptions="{ itemSize: 40 }"
                    responsiveLayout="scroll" :loading="loading" :paginator="false" :lazy="false" class="p-mt-3">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <Calendar placeholder="统计时间" v-model="storeTime" dateFormat="yy-mm-dd" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button type="button" label="查询" class="mb-2"
                                        @click="customFilter();" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-external-link" label="导出" @click="exportData()"
                                        class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-filter-slash" label="锁定"
                                        class="p-button-outlined mb-2" @click="saveStatData()" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="sku" header="SKU" style="width:130px" class="myth" frozen :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.sku, disabled: false }">{{slotProps.data.sku}}</label>
                        </template>
                    </Column>
                    <Column field="tagname" header="产品分类" style="width:100px" class="myth" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:100px"
                                v-tooltip.top="{ value: slotProps.data.tagname, disabled: false }">{{slotProps.data.tagname}}</label>
                        </template>
                    </Column>
                    <Column field="productname" header="产品名" style="width:160px" class="myth" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                        </template>
                    </Column>
                    <Column field="inprocessinventory" style="width:130px" class="myth" header="在产库存" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.inprocessinventory, disabled: false }">{{slotProps.data.inprocessinventory}}</label>
                        </template>
                    </Column>
                    <Column field="shipinventory" style="width:130px" class="myth" header="在途库存" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.shipinventory, disabled: false }">{{slotProps.data.shipinventory}}</label>
                        </template>
                    </Column>
                    <Column field="actualinventory" style="width:130px" class="myth" header="实际库存" :sortable="false">
                        <template #body="slotProps">
                            <InputText
                                v-if="!slotProps.data['id'] || slotProps.data['id']=='' || parseInt(slotProps.data['id'])<=0"
                                type="text" @blur="actualinventoryBlur(slotProps.data.actualinventory,slotProps.data)"
                                style="width:126px" v-model="slotProps.data.actualinventory" class="p-column-filter"
                                placeholder="" />
                            <label
                                v-if="slotProps.data['id'] && slotProps.data['id']!='' && parseInt(slotProps.data['id'])>0"
                                class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.actualinventory, disabled: false }">{{slotProps.data.actualinventory}}</label>
                        </template>
                    </Column>
                    <Column field="theoryinventory" style="width:130px" class="myth" header="理论库存" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.theoryinventory, disabled: false }">{{slotProps.data.theoryinventory}}</label>
                        </template>
                    </Column>
                    <Column field="threesalesspeed" style="width:130px" class="myth" header="三天销速" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.threesalesspeed, disabled: false }">{{slotProps.data.threesalesspeed}}</label>
                        </template>
                    </Column>
                    <Column field="threesalesday" style="width:130px" class="myth" header="可售天数" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.threesalesday, disabled: false }">{{slotProps.data.threesalesday}}</label>
                        </template>
                    </Column>
                    <Column field="sevensalesspeed" style="width:130px" class="myth" header="七天销速" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.sevensalesspeed, disabled: false }">{{slotProps.data.sevensalesspeed}}</label>
                        </template>
                    </Column>
                    <Column field="sevensalesday" style="width:130px" class="myth" header="可售天数" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.sevensalesday, disabled: false }">{{slotProps.data.sevensalesday}}</label>
                        </template>
                    </Column>
                    <Column field="fifteensalesspeed" style="width:130px" class="myth" header="十五天销速" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.fifteensalesspeed, disabled: false }">{{slotProps.data.fifteensalesspeed}}</label>
                        </template>
                    </Column>
                    <Column field="fifteensalesday" style="width:130px" class="myth" header="可售天数" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.fifteensalesday, disabled: false }">{{slotProps.data.fifteensalesday}}</label>
                        </template>
                    </Column>
                    <Column field="selfthreesalesspeed" style="width:160px" class="myth" header="自营三天销速"
                        :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.selfthreesalesspeed, disabled: false }">{{slotProps.data.selfthreesalesspeed}}</label>
                        </template>
                    </Column>
                    <Column field="selfthreesalesday" style="width:130px" class="myth" header="可售天数" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.selfthreesalesday, disabled: false }">{{slotProps.data.selfthreesalesday}}</label>
                        </template>
                    </Column>
                    <Column field="selfsevensalesspeed" style="width:160px" class="myth" header="自营七天销速"
                        :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.selfsevensalesspeed, disabled: false }">{{slotProps.data.selfsevensalesspeed}}</label>
                        </template>
                    </Column>
                    <Column field="selfsevensalesday" style="width:130px" class="myth" header="可售天数" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.selfsevensalesday, disabled: false }">{{slotProps.data.selfsevensalesday}}</label>
                        </template>
                    </Column>
                    <Column field="selffifteensalesspeed" style="width:160px" class="myth" header="自营十五天销速"
                        :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.selffifteensalesspeed, disabled: false }">{{slotProps.data.selffifteensalesspeed}}</label>
                        </template>
                    </Column>
                    <Column field="selffifteensalesday" style="width:130px" class="myth" header="可售天数"
                        :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:130px"
                                v-tooltip.top="{ value: slotProps.data.selffifteensalesday, disabled: false }">{{slotProps.data.selffifteensalesday}}</label>
                        </template>
                    </Column>
                    <Column field="eta" style="width:160px" class="myth" header="在途ETA" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.eta, disabled: false }">{{slotProps.data.eta}}</label>
                        </template>
                    </Column>
                    <Column field="shipdate" style="width:160px" class="myth" header="预计发货日期" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.shipdate, disabled: false }">{{slotProps.data.shipdate}}</label>
                        </template>
                    </Column>
                    <Column field="putinstoragedate" style="width:160px" class="myth" header="入库日期" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.putinstoragedate, disabled: false }">{{slotProps.data.putinstoragedate}}</label>
                        </template>
                    </Column>
                    <Column field="storageday" style="width:160px" class="myth" header="在库天数" :sortable="false">
                        <template #body="slotProps">
                            <label class="oneline" style="width:160px"
                                v-tooltip.top="{ value: slotProps.data.storageday, disabled: false }">{{slotProps.data.storageday}}</label>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
</template>
<style type="text/css">
    .oneline {
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .myth {
        flex: none !important;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import commonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '800';
            return {
                confirm,
                currentMenuId,
            };
        },
        data() {
            return {
                matchModes: [],
                timeMatchModes: [],
                numMatchModes: [],
                butLoading: false,
                position: 'bottomleft',
                filters1: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                listTitle: '库存销速统计',
                selectedKey: {
                    800: true
                },
                expandedKey: null,
                storeTime: '',
                statdata: [],
                loading: false,
                nodes: [],
                items: [{
                    label: '统计操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '导出',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.exportData();
                        }
                    }, ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                allStoreMoney: 0,
                currencyName: '',
                exchangeRate: 1,
                allStoreRMBMoney: 0,
            }
        },
        created() {
            MessageTip.initMsg();
            this.storeTime = commonJs.GetCurrentDate();
            this.loadMenuTree();
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
            this.minHeight = "height:" + (window.innerHeight - 132) + "px";
        },
        methods: {
            customFilter() {
                this.loading = true;
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            clearFilter1() {
                this.storeTime = '';
            },
            loadMenuTree() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '731',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usertreemenulist(where:" + JSON.stringify(listwhere) +
                    "){key label data nodetype icon leaf children {key label data nodetype parentid parenturl icon leaf} }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.nodes = jsonData.data.usertreemenulist;
                        if (this.$route.query.nodeid && this.$route.query.nodeid != '' && this.nodes) {
                            this.selectedKey = {};
                            this.selectedKey[this.$route.query.nodeid] = true;
                            this.expandedKey = {};
                            this.expandedKey[this.currentMenuId] = true;
                            var isFind = false;
                            for (var i = 0; i < this.nodes.length; i++) {
                                for (var j = 0; j < this.nodes[i].children.length; j++) {
                                    if (this.nodes[i].children[j].key == this.$route.query.nodeid) {
                                        isFind = true;
                                        this.queryPlanObj = {
                                            name: this.nodes[i].children[j].label,
                                            id: this.nodes[i].children[j].key,
                                            parentid: this.currentMenuId,
                                            queryconfig: this.nodes[i].children[j].data,
                                        };
                                        if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig !=
                                            '') {
                                            this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                        }
                                        this.listTitle = this.nodes[i].children[j].label;
                                        break;
                                    }
                                }
                                if (isFind) {
                                    break;
                                }
                            }
                        }
                        this.loadData(1);
                    }
                });
            },
            onNodeSelect(node) {
                console.log(node);
                setTimeout(() => {
                    var currentKey = '';
                    if (this.selectedKey) {
                        for (var key in this.selectedKey) {
                            currentKey = key;
                            break;
                        }
                    }
                    if (node.nodetype == '1') {
                        this.queryPlanObj = {
                            name: '',
                            id: '-1',
                            parentid: this.currentMenuId,
                            queryconfig: '',
                        };
                        console.log('node.key:' + node.key);
                        console.log('selectedKey:');
                        console.log(this.selectedKey);
                        console.log('currentKey:' + currentKey);
                        if (node.key != this.currentMenuId) {
                            window.location.href = node.data;
                        } else {
                            this.loadData(1);
                            this.listTitle = "库存销速统计";
                        }
                    }
                    if (node.nodetype == '2') {
                        if (this.currentMenuId != node.parentid) {
                            window.location.href = node.parenturl + "?nodeid=" + node.key;
                        } else {
                            this.queryPlanObj = {
                                name: node.label,
                                id: node.key,
                                parentid: this.currentMenuId,
                                queryconfig: node.data,
                            };
                            console.log('expandedKey:');
                            console.log(this.expandedKey);
                            if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig != '') {
                                console.log('queryconfig:');
                                console.log(this.queryPlanObj.queryconfig);
                                this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                this.loadData(1);
                                this.listTitle = node.label;
                            }
                        }
                    }
                }, 20);
            },
            loadData(v_page) {
                if (this.storeTime == '') {
                    MessageTip.warnmsg('请选择统计时间');
                    return;
                }
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: -1,
                    conditions: [{
                        name: 'statdate',
                        value: this.storeTime,
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{productsalesspeedlist(where:" + JSON.stringify(listwhere) +
                    "){id sku mpn productname tagname productid inprocessinventory shipinventory actualinventory theoryinventory threesalesspeed sevensalesspeed fifteensalesspeed fifteensalesday selfthreesalesspeed selfsevensalesspeed selffifteensalesspeed selfthreesalesday selfsevensalesday selffifteensalesday eta shipdate sevensalesday threesalesday putinstoragedate storageday statdate }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.statdata = jsonData.data.productsalesspeedlist;
                    }
                });
            },
            saveStatData() {
                this.$confirm.require({
                    message: '确认要锁定统计数据?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.statdata || this.statdata.length == 0) {
                            MessageTip.warnmsg('无数据可以锁定');
                            return;
                        }
                        if (this.statdata[0]['id'] != '' && parseInt(this.statdata[0]['id']) > 0) {
                            MessageTip.warnmsg('数据已经锁定');
                            return;
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "mutation{productsalesspeedlist(o:" + JSON.stringify(this.statdata) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                this.loadData(1);
                                MessageTip.successmsg('数据锁定成功');
                            } else {
                                MessageTip.warnmsg('数据锁定失败');
                            }
                        });
                    },
                    reject: () => {}
                });
            },
            closeMaximizable() {},
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            exportData() {
                this.$refs.dt.exportCSV();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            actualinventoryBlur(v_value, v_row) {
                console.log('actualinventoryBlur....');
                console.log(v_value);
                console.log(v_row);
                var actualinventory = Number(v_value);
                var threeSalesSpeed = Number(v_row['threesalesspeed']);
                var sevenSalesSpeed = Number(v_row['sevensalesspeed']);
                var fifteenSalesSpeed = Number(v_row['fifteensalesspeed']);
                var selfThreeSalesSpeed = Number(v_row['selfthreesalesspeed']);
                var selfSevenSalesSpeed = Number(v_row['selfsevensalesspeed']);
                var selfFifteenSalesSpeed = Number(v_row['selffifteensalesspeed']);
                if (!isNaN(actualinventory)) {
                    if (actualinventory == 0) {
                        v_row['threesalesday'] = '没货';
                        v_row['sevensalesday'] = '没货';
                        v_row['fifteensalesday'] = '没货';
                        v_row['selfthreesalesday'] = '没货';
                        v_row['selfsevensalesday'] = '没货';
                        v_row['selffifteensalesday'] = '没货';
                    } else {
                        if (!isNaN(threeSalesSpeed) && threeSalesSpeed > 0) {
                            v_row['threesalesday'] = (actualinventory / threeSalesSpeed).toFixed(0);
                        } else {
                            v_row['threesalesday'] = '不动销';
                        }
                        if (!isNaN(sevenSalesSpeed) && sevenSalesSpeed > 0) {
                            v_row['sevensalesday'] = (actualinventory / sevenSalesSpeed).toFixed(0);
                        } else {
                            v_row['sevensalesday'] = '不动销';
                        }
                        if (!isNaN(fifteenSalesSpeed) && fifteenSalesSpeed > 0) {
                            v_row['fifteensalesday'] = (actualinventory / fifteenSalesSpeed).toFixed(0);
                        } else {
                            v_row['fifteensalesday'] = '不动销';
                        }
                        if (!isNaN(selfThreeSalesSpeed) && selfThreeSalesSpeed > 0) {
                            v_row['selfthreesalesday'] = (actualinventory / selfThreeSalesSpeed).toFixed(0);
                        } else {
                            v_row['selfthreesalesday'] = '不动销';
                        }
                        if (!isNaN(selfSevenSalesSpeed) && selfSevenSalesSpeed > 0) {
                            v_row['selfsevensalesday'] = (actualinventory / selfSevenSalesSpeed).toFixed(0);
                        } else {
                            v_row['selfsevensalesday'] = '不动销';
                        }
                        if (!isNaN(selfFifteenSalesSpeed) && selfFifteenSalesSpeed > 0) {
                            v_row['selffifteensalesday'] = (actualinventory / selfFifteenSalesSpeed).toFixed(0);
                        } else {
                            v_row['selffifteensalesday'] = '不动销';
                        }
                    }
                }
            },
        },
        components: {
            'AppFooter': AppFooter,
        }
    }
</script>